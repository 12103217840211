<template>
  <div class="container is-fluid">
    <div class="columns">
      <div class="column is-8 is-offset-2">
        <div class="card">
          <header class="card-header">
            <p class="card-header-title">Dashboard</p>
          </header>
          <div class="card-content">
            <p>
              <b>Welkom op het dashboard van Hike and Seek.</b><br />
              Het dashboard is ons gebruikersplatform voor deelnemers, zij
              kunnen hier hun gegevens bewerken en informatie vinden over het
              weekend.<br />
              Tevens opent hier op 29-11-2024 om 21.30 de inschrijving van Hike and Seek.<br />
              <br />
              <b>Ben je nieuw?</b><br />
              <a href="https://hikeandseek.nl">
                Lees hier alle informatie over Hike and Seek.</a
              ><br />
              <br />
              <b>Heb je nog vragen?</b><br />
              Wij zijn te bereiken via:
              <a href="mailto:info@hikeandseek.nl"> info@hikeandseek.nl</a>.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "home",
  components: {},
};
</script>
